/**
 * Middleware for cart routes where a (guest) login is required
 */
import { useCheckoutStore } from "~/stores/checkout.store";

export default defineNuxtRouteMiddleware(() => {
  const { cartContext } = storeToRefs(useCheckoutStore());

  if (cartContext.value && cartContext.value.isAnonymousCart()) {
    return navigateTo({ name: "checkoutLogin", params: { baseSite: cartContext.value.bs } });
  }
});
